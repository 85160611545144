/**
 * Post Template
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Bannerwithcta from "../components/bannerwithcta"
import Headerrelease from "../components/headerrelease"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/templates/releasepost.scss"


/* Declaration function */
const ReleasePostTemplate = ({ data }) => {

  //Const Data
  const dataRelease = data.wpRelease
  var repeaterLevels = dataRelease.acfPostRelease.repeaterLevel1
  var optionsReleaseFooter = dataRelease.acfPostRelease.optionsReleasesFooter

  //Types de release (features, coming soon)
  let arrTypesRelease = []
  if (repeaterLevels !== null) {
    repeaterLevels.map( (level1 )  => (
      arrTypesRelease.push(level1.typeLevel1[0])
    ))
    const unique = (value, index, self) => {
      return self.indexOf(value) === index
    }
    var arrUniqueTypesRelease = arrTypesRelease.filter(unique)
    var arrLevelofTypeFeatures = []
    var arrLevelofTypeComingsoon = []
    arrUniqueTypesRelease.filter(item => item === "features").map( (type) => (
      arrLevelofTypeFeatures = repeaterLevels.filter(function(element) {
        return element.typeLevel1[0] === type
      })
    ))
    arrUniqueTypesRelease.filter(item => item === "comingsoon").map( (type) => (
      arrLevelofTypeComingsoon = repeaterLevels.filter(function(element) {
        return element.typeLevel1[0] === type
      })
    ))
  }

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "support2"
  })

  return (
    <Layout>
      <Seo
        title={`${dataRelease.title} - Hello Health US Platform`}
        description={`${dataRelease.title} - Read Hello Health’s newest release note containing valuable information and screenshots about product enhancements and platform changes.`}
      />
      <section className="section-post-release top-section section-hero-static">
        <article>
          <Headerrelease h1title={dataRelease.title} releaseDate={dataRelease.date} />
          {repeaterLevels !== null ? (
            <section className="section-details-repeater">
              <div className="wrap-maincontent wrap-repeater-release-notes">
                <div className="nav-internal-release">
                  <div className="wrap-nav-internal-release">
                    <ul className="list-nav-internal-release">
                      {repeaterLevels.map( (item1, index1 )  => (
                        <li key={index1} className="list-level-1">{item1.titleLevel1}
                        {item1.repeaterLevel2 !== null && (
                          <ul className="list-subnav-internal-release">
                            {item1.repeaterLevel2.map( (item2, index2) =>
                              <li key={index2} className="list-level-2"><AnchorLink offset='120' href={`#release-${index1}-${index2}`}>{item2.titleLevel2}</AnchorLink></li>
                            ) }
                          </ul>
                        )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="main-content-release">
                  <p className="type-updates first-type">Features Updates</p>
                  {arrLevelofTypeFeatures.map( (item1, index1 )  => (
                    <div className="repeater-release">
                      <h2>{item1.titleLevel1}</h2>
                      {item1.contentLevel1 !== "" && <div className="post-content" dangerouslySetInnerHTML={{ __html:item1.contentLevel1}} /> }
                      {item1.repeaterLevel2 !== null && (
                      <div>
                        {item1.repeaterLevel2.map( (item2, index2) =>
                          <div id={`release-${index1}-${index2}`}>
                            <h3>{item2.titleLevel2}</h3>
                            <div className="post-content" dangerouslySetInnerHTML={{ __html:item2.contentLevel2}} />
                          </div>
                        ) }
                      </div>
                    )}
                    </div>
                  ))}
                  {arrLevelofTypeComingsoon.length > 0 && [
                    <div>
                      <p className="type-updates">Coming Soon</p>
                      {arrLevelofTypeComingsoon.map( (item1, index1 )  => (
                        <div className="repeater-release">
                          <h2>{item1.titleLevel1}</h2>
                          {item1.contentLevel1 !== "" && <div className="post-content" dangerouslySetInnerHTML={{ __html:item1.contentLevel1}} /> }
                          {item1.repeaterLevel2 !== null && (
                          <div>
                            {item1.repeaterLevel2.map( (item2, index2) =>
                              <div id={`release-${index1}-${index2}`}>
                                <h3>{item2.titleLevel2}</h3>
                                <div className="post-content" dangerouslySetInnerHTML={{ __html:item2.contentLevel2}} />
                              </div>
                            ) }
                          </div>
                        )}
                        </div>
                      ))}
                    </div>
                  ]}
                  <h2>{data.options.acfPageOptions.sentenceReleasesPlusFooter}</h2>
                  {optionsReleaseFooter !== null && [
                    <div className="options-releases-footer">

                      <div className="post-content" dangerouslySetInnerHTML={{ __html:data.options.acfPageOptions.textReleasesFooter}} />
                    </div>
                  ]}
                </div>
              </div>
            </section>
          ) : (
            <section className="section-details-static">
              <div className="wrap-maincontent-static">
                <p className="type-updates">Features Updates</p>
                <div className="maincontent-static post-content" dangerouslySetInnerHTML={{ __html:dataRelease.content}} />
              </div>
            </section>
          )
        }

        </article>
      </section>

      {/* Banner CTA */}
      <Bannerwithcta
        classBanner="section-banner-releases"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}
/* Export template informations */
export default ReleasePostTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($id: Int!) {
    wpRelease(databaseId: { eq: $id }) {
      title
      slug
      content
      date (
        formatString: "MMMM DD, YYYY"
      )
      acfPostRelease {
        repeaterLevel1 {
          contentLevel1
          titleLevel1
          typeLevel1
          repeaterLevel2 {
            contentLevel2
            titleLevel2
          }
        }
        optionsReleasesFooter
      }
      seo {
        title
        metaDesc
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
        sentenceReleasesPlusFooter
        textReleasesFooter
      }
    }
  }
`
