/**
 * Class element
 * Header Release component
 * Display the hero of the release post page
 */
/* Import section */
import React from "react"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import Titledecorative from "../components/titledecorative"
import "../styles/components/headerrelease.scss"

/* Declaration class */
const Headerrelease = ( { h1title, releaseDate } ) => {
  return (
    <header className="header-release">
      <div className="wrap-header-release">
        <div className="back-release">
            <Ctabuttonwitharrow ctaLabel="Back to Product Updates" ctaLink="/product-updates" colorStyle="white-format" specificStyle="text-arrow" arrow="left" />
          </div>
        <div className="content-header-release">
          <p className="type-date"><span>{releaseDate}</span></p>
          <Titledecorative titleType="h1" title= {h1title} />
        </div>
      </div>
    </header>
  )
}

/* Export class */
export default Headerrelease
